<template>
  <div class="admin-landing-msg">
    <b-row class="mb-4">
      <b-col>
        <h1>Welcome to your Candidate Dashboard.</h1>
      </b-col>
    </b-row>
    <b-row class="mb-2 description">
      <b-col md="7">
        <h5>
          This website builder will walk you through the steps of creating and
          publishing your candidate website. There will be no charge to you for
          hosting the site, and it will remain active as long as you're an
          eligible candidate.
        </h5>
      </b-col>
      <b-col md="5">
        <p class="text-muted">
          Use the <span class="font-weight-bold">Preview</span> button below to
          view your site before you publish it. If you need help, contact
          Thinklogic, our third-party vendor, using the
          <span class="font-weight-bold">Help</span> button in the top right
          corner.
        </p>
        <p class="text-muted quick-start-text">
          If you're new to the website builder or need a brief introduction, you
          can view our
          <router-link to="quick-start">
            Quick Start Guide <font-awesome-icon :icon="['fas', 'book-open']"
          /></router-link>
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-button
          variant="primary"
          size="lg"
          @click="onStartContinue"
          v-show="showPrimary"
          style="display: none"
        >
          {{ showContinue ? "Continue" : "Get Started" }}
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </b-button>
        <b-button
          @click="onPreview"
          variant="outline-secondary"
          size="lg"
          v-show="showPreview"
        >
          Preview
          <font-awesome-icon :icon="['fas', 'eye']" />
        </b-button>
        <b-button
          v-b-modal.modal-publish
          :variant="saving ? 'outline-secondary' : 'danger'"
          size="lg"
          v-if="!disablePublish && showPublish"
          :disabled="saving"
        >
          <b-spinner
            small
            style="position: relative; top: -3px"
            class="mr-2"
            v-if="saving"
          ></b-spinner>
          Publish
          <font-awesome-icon :icon="['fas', 'arrow-right']" v-if="!saving" />
        </b-button>
        <b-button
          @click="onLiveSite"
          size="lg"
          variant="success"
          v-else
          v-show="showPreview"
        >
          Live Site <font-awesome-icon :icon="['fas', 'globe']" />
        </b-button>
        <b-button
          v-if="isAdmin"
          v-b-modal.modal-delete
          :variant="deleting ? 'outline-secondary' : 'danger'"
          size="lg"
          :disabled="deleting"
        >
          <b-spinner
            small
            style="position: relative; top: -3px"
            class="mr-2"
            v-if="deleting"
          ></b-spinner>
          Delete
          <font-awesome-icon :icon="['fas', 'trash-alt']" v-if="!deleting" />
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="modal-publish"
      title="Publish"
      @ok="handleOk"
      ok-title="Yes"
      cancel-title="No"
    >
      <div v-if="hasPendingStatements">
        <p>
          Prior to publishing the text of your non-candidate statement(s),
          please send the text to the Election Administrator to verify that it
          is in compliance.
        </p>
        <p>
          Once your non-candidate statement is published, you will no longer be
          able to edit your <strong>name</strong> or the <strong>text</strong>.
        </p>
        <p>You will be able to add an additional statement(s) if needed.</p>
        <p class="alert alert-primary">
          Do you still want to publish your changes?
        </p>
      </div>
      <div v-else-if="!candidateModel.hasPublishedContent">
        <p>
          Once you publish your website you will not be able to edit your
          <strong>Name</strong>. Please make sure your name is correct prior to
          publishing.
        </p>
        <p class="alert alert-primary">
          Do you still want to publish your changes?
        </p>
      </div>
      <div v-else>
        <p class="alert alert-primary">
          Are you sure you want to publish your changes?
        </p>
      </div>
    </b-modal>

    <b-modal
      id="modal-delete"
      title="Delete"
      @ok="handleDelete"
      ok-title="Yes"
      cancel-title="No"
    >
      <div>
        <p>
          This action will delete candidate
          <strong>{{ candidate.name }}</strong> from the system and cannot be
          undone.
        </p>
        <p class="alert alert-primary">
          Do you still want to proceed?
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  mixins: [CandidateMixin],
  data() {
    return {
      saving: false,
      deleting: false,
      disablePublish: false,
      isAdmin: false
    };
  },
  created() {
    this.init();

    if (this.$store.getters["auth/isAuthenticated"]) {
      this.isAdmin = this.$store.state.auth.user.roles.includes("Admin");
    }
  },
  computed: {
    showContinue() {
      return this.$store.getters["candidates/showContinue"];
    },
    showPublish() {
      return this.$store.getters["candidates/showPublish"];
    },
    showPrimary() {
      return this.$store.getters["candidates/showContinue"] || true;
    },
    showPreview() {
      return this.$store.getters["candidates/showPublish"];
    },
    hasPendingStatements() {
      return this.$store.getters["candidates/hasPendingStatements"];
    },
    publishedSinceLastSave() {
      return this.$store.getters["candidates/publishedSinceLastSave"];
    },
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }

    // disablePublish() {
    //   return this.$store.getters["candidates/publishedSinceLastSave"];
    // },
  },
  methods: {
    init() {
      this.primaryBtn = this.continue ? "Continue" : "Get Started";
      this.disablePublish = this.$store.getters[
        "candidates/publishedSinceLastSave"
      ];
    },
    onStartContinue() {
      const nextPath = this.$store.getters["candidates/nextProgressPath"];
      this.$router.push(nextPath);
    },
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      this.saving = true;

      this.$nextTick(() => {
        this.$bvModal.hide("modal-publish");
      });

      setTimeout(async () => {
        try {
          let userId = this.$route.params.id;
          await this.$store.dispatch("candidates/publishCandidate", userId);
          this.loading = false;
          this.saving = false;
        } catch (error) {
          this.errorMessage = error;
        }
        this.disablePublish = true;
      }, 1000);
    },
    async handleDelete(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;
      this.deleting = true;

      this.$nextTick(() => {
        this.$bvModal.hide("modal-delete");
      });

      setTimeout(async () => {
        try {
          let userId = this.$route.params.id;
          await this.$store.dispatch("candidates/deleteCandidate", userId);
          this.loading = false;
          this.deleting = false;
          this.$router.push(
            `/admin/${this.$store.state.auth.user.id}/manage-users`
          );
        } catch (error) {
          this.errorMessage = error;
        }
      }, 1000);
    },
    onLiveSite() {
      let routeData = this.$router.resolve(
        `/${new Date().getFullYear()}/` +
          this.$store.state.candidates.candidate.slug
      );
      window.open(routeData.href, "_blank");
    },
    onPreview() {
      let routeData = this.$router.resolve(
        "/preview/candidate/" + this.$store.state.candidates.candidate.slug
      );
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base";
.admin-landing-msg {
  padding: 30px 0;

  h1 {
    font-family: $montserrat;
    font-weight: 700;
    font-size: 32px;
  }

  h5 {
    font-weight: 400;
    line-height: 28px;
  }

  .description {
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 20px;
    margin-right: 20px;

    svg {
      margin-left: 5px;
    }
  }

  .quick-start-text {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: $lightgray;
    border-radius: 5px;
  }
}
</style>
